import { StateCreator } from 'zustand';
import { AttachmentDto, AttachmentType, DentalMovementPair, TooltipAttach } from '../../../common';

export interface OrthAttachesSlice {
  attachActive: AttachmentType | null;
  setAttachActive: (attachActive: AttachmentType) => void;
  hoveredAttach: TooltipAttach | null;
  setHoveredAttach: (hoveredAttach: TooltipAttach | null) => void;
  isAttacheSelected: boolean;
  setIsAttacheSelected: (isAttacheSelected: boolean) => void;
  selectedAttache: TooltipAttach | null;
  setSelectedAttache: (selectedAttache: TooltipAttach | null) => void;
  areAttachesSet: boolean;
  setAreAttachesSet: (areAttachesSet: boolean) => void;
  dentalMovementsSnapshot: DentalMovementPair | null;
  setDentalMovementsSnapshot: (dentalMovementsSnapshot: DentalMovementPair) => void;
  attachmentTypeToBeAdded: AttachmentType | null;
  setAttachementTypeToBeAdded: (attachmentTypeToBeAdded: AttachmentType) => void;
  addingAttache: AttachmentDto | null;
  setAddingAttache: (isAddingAttache: AttachmentDto | null) => void;
}

export const createOrthAttachesSlice: StateCreator<OrthAttachesSlice, [], [], OrthAttachesSlice> = (set) => ({
  attachActive: null,
  setAttachActive: (attachActive: AttachmentType) => set((_) => ({ attachActive })),
  hoveredAttach: null,
  setHoveredAttach: (hoveredAttach: TooltipAttach | null) => set((_) => ({ hoveredAttach })),
  isAttacheSelected: false,
  setIsAttacheSelected: (isAttacheSelected: boolean) => set((_) => ({ isAttacheSelected })),
  selectedAttache: null,
  setSelectedAttache: (selectedAttache: TooltipAttach | null) => set((_) => ({ selectedAttache })),
  areAttachesSet: false,
  setAreAttachesSet: (areAttachesSet: boolean) => set((_) => ({ areAttachesSet })),
  dentalMovementsSnapshot: null,
  setDentalMovementsSnapshot: (dentalMovementsSnapshot: DentalMovementPair) =>
    set((_) => ({ dentalMovementsSnapshot })),
  attachmentTypeToBeAdded: null,
  setAttachementTypeToBeAdded: (attachmentTypeToBeAdded: AttachmentType) => set((_) => ({ attachmentTypeToBeAdded })),
  addingAttache: null,
  setAddingAttache: (isAddingAttache: AttachmentDto | null) => set((_) => ({ addingAttache: isAddingAttache }))
});
