import { useTranslation } from 'react-i18next';
import { ReactComponent as MinusIcon } from '../../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { useModifyAttachesStep, OperationType } from './useModifyAttachesStep';
import './modifyAttachesStep.scss';
import { useCallback } from 'react';

interface ModifyAttachesInitialStepProps {
  onClickOutside: () => void;
}

export function ModifyAttachesInitialStep({ onClickOutside }: ModifyAttachesInitialStepProps) {
  const [t] = useTranslation();
  const { modifyingAttach, setModifyingAttach, dropdownRef, evergineReady, updateMovements } = useModifyAttachesStep({
    onClickOutside
  });

  const modifyAttachStep = useCallback(
    (operationType: OperationType) => {
      if (!evergineReady || !modifyingAttach) return;
      if (modifyingAttach.firstStep === 1 && operationType === OperationType.SUBSTRACT) return;

      let newValue = modifyingAttach.firstStep;
      newValue += operationType === OperationType.ADD ? 1 : -1;
      if (newValue >= modifyingAttach.lastStep) return;

      window.App.webEventsProxy.attachments.updateAttachmentSteps(
        modifyingAttach.id,
        newValue,
        modifyingAttach.lastStep,
        modifyingAttach.firstStep
      );

      const newAttachData = { ...modifyingAttach, firstStep: newValue };
      setModifyingAttach(newAttachData);
      updateMovements();
    },
    [evergineReady, modifyingAttach]
  );

  return (
    <div className="attachesstep" ref={dropdownRef}>
      <div className="attachesstep-step">{t('contextMenuOptions.firstStep')}</div>
      <div className="attachesstep-operator" onClick={() => modifyAttachStep(OperationType.SUBSTRACT)}>
        <MinusIcon />
      </div>
      <div>Step&nbsp;{modifyingAttach?.firstStep ?? '--'}</div>
      <div className="attachesstep-operator" onClick={() => modifyAttachStep(OperationType.ADD)}>
        <PlusIcon />
      </div>
    </div>
  );
}
