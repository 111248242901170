import { AttachmentType, DentalMovementGroup, DentalMovementPair, VirtualTemplateType } from '../../common';
import { isTadType } from '../../orthodontics';

export const VIRTUAL_TEMPLATE_INDEX_INTERVAL = 0.5;

const getAttachmentTypeEnumFromStringValue = (value: string) => AttachmentType[value as keyof typeof AttachmentType];

export function useVirtualTemplates() {
  const getMovementsWithVirtualTemplates = (dentalMovements: DentalMovementPair): DentalMovementPair => {
    const attaches = new Set<number>();

    dentalMovements.upperDentalMovements?.attachments.forEach((attachment: any) => {
      if (!isTadType(getAttachmentTypeEnumFromStringValue(attachment.type))) {
        attaches.add(attachment.firstStep);
      }
    });
    dentalMovements.lowerDentalMovements?.attachments.forEach((attachment: any) => {
      if (!isTadType(getAttachmentTypeEnumFromStringValue(attachment.type))) {
        attaches.add(attachment.firstStep);
      }
    });
    const modifiedUpper = addPositiveAttachesTemplateSteps(dentalMovements.upperDentalMovements, attaches);
    const modifiedLower = addPositiveAttachesTemplateSteps(dentalMovements.lowerDentalMovements, attaches);

    const upperStepsLength = modifiedUpper?.steps.length ?? 0;
    const lowerStepsLength = modifiedLower?.steps.length ?? 0;

    const maxSteps = Math.max(upperStepsLength, lowerStepsLength);

    return {
      lowerDentalMovements: addPassiveAlignerTemplateStepsIfRequired(modifiedLower, maxSteps),
      upperDentalMovements: addPassiveAlignerTemplateStepsIfRequired(modifiedUpper, maxSteps)
    };
  };

  const addPassiveAlignerTemplateStepsIfRequired = (dentalMovement: DentalMovementGroup, targetStepCount: number) => {
    if (!dentalMovement) return;
    const steps = [...dentalMovement.steps];
    const currentStepCount = steps.length;

    if (currentStepCount < targetStepCount) {
      const lastStep = steps[currentStepCount - 1];
      let passiveAlignerCount = 1;
      let stepIndex = lastStep.stepIndex + 1;
      for (let i = currentStepCount; i < targetStepCount; i++) {
        steps.push({
          ...lastStep,
          stepIndex,
          virtualTemplateType: VirtualTemplateType.PassiveAligner,
          virtualTemplateIndex: `P${passiveAlignerCount}`
        });
        passiveAlignerCount++;
        stepIndex++;
      }
    }

    return { ...dentalMovement, steps };
  };

  const addPositiveAttachesTemplateSteps = (
    dentalMovement: DentalMovementGroup | null,
    stepsToInsert: Set<number>
  ): DentalMovementGroup | null => {
    if (dentalMovement === null) {
      return null;
    }

    const newSteps = [...dentalMovement.steps];

    stepsToInsert.forEach((stepIndexToInsert) => {
      const stepWithAttachmentIndex = newSteps.findIndex((step) => step.stepIndex === stepIndexToInsert);

      if (stepWithAttachmentIndex > 0) {
        const previousStep = newSteps[stepWithAttachmentIndex - 1];

        const newStep = {
          ...previousStep,
          virtualTemplateType: VirtualTemplateType.PositiveAttache,
          virtualTemplateIndex: `T${previousStep.stepIndex + 1}`
        };

        newStep.stepIndex = previousStep.stepIndex + VIRTUAL_TEMPLATE_INDEX_INTERVAL;

        newSteps.splice(stepWithAttachmentIndex, 0, newStep);
      }
    });

    return {
      ...dentalMovement,
      steps: newSteps
    };
  };

  return {
    getMovementsWithVirtualTemplates
  };
}
