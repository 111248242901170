import './modalDeleteLabel.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { AttachmentStepMode } from '../../../../../common';
import Radio from '../../../../../common/components/radio/Radio';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

export function ModalDeleteLabel() {
  const [t] = useTranslation();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const { updateMovements } = useUtils();
  const [selectedOption, setSelectedOption] = useState<number>(AttachmentStepMode.AllSteps);
  const selectedAttache = useOrthBoundStore((state) => state.selectedAttache);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const handleContinue = () => {
    if (selectedAttache && selectedAttache.id) {
      window.App.webEventsProxy.attachments.removeAttachment(selectedAttache.id, selectedOption);
    }

    updateMovements();
    toggleModalIsOpened('');
  };

  const handleSelected = (selectedOption: number) => {
    setSelectedOption(selectedOption);
  };

  const isActive = (option: number) => {
    return selectedOption === option;
  };

  return (
    <div className="modal-content modal-delete-label" data-testid={'modal-deleteLabel'}>
      <div className="modal-header modal-delete-label">
        <Info className="modal-icon" />
        <div className="modal-title">{t(`modal.deleteLabel.title`)}</div>
      </div>
      <div className="modal-radios modal-delete-label">
        <Radio
          label={t(`modal.delete.options.deleteInAllSteps`)}
          active={isActive(AttachmentStepMode.AllSteps)}
          callBack={() => handleSelected(AttachmentStepMode.AllSteps)}
          expanded={false}
        />
        <Radio
          label={t(`modal.delete.options.deleteFromActualStepToLast`)}
          active={isActive(AttachmentStepMode.FromCurrentStepToEnd)}
          callBack={() => handleSelected(AttachmentStepMode.FromCurrentStepToEnd)}
          expanded={false}
        />
        <Radio
          label={t(`modal.delete.options.deleteFromActualStepToInitial`)}
          active={isActive(AttachmentStepMode.FromCurrentStepToStart)}
          callBack={() => handleSelected(AttachmentStepMode.FromCurrentStepToStart)}
          expanded={false}
        />
      </div>

      <div className="modal-buttons modal-delete-label">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button
          onClick={handleContinue}
          disabled={selectedOption === null}
          type="button"
          className="btn btn-primary flex-fill modal-button"
        >
          {t(`modal.actions.delete`)}
        </button>
      </div>
    </div>
  );
}
