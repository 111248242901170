import { ReactComponent as GoToNextIcon } from '../../../assets/icons/player-next.svg';
import { ReactComponent as GoToPreviousIcon } from '../../../assets/icons/player-previous.svg';
import { ReactComponent as EllipsisIcon } from '../../../assets/icons/ellipsis.svg';

import './evolutionPanelNavigator.scss';
import React from 'react';
import { useEvolutionPanelNavigator } from '../../../hooks';

interface EvolutionPanelNavigatorProps {
  lastStepIndex: number;
}

function EvolutionPanelNavigator(props: EvolutionPanelNavigatorProps) {
  const { lastStepIndex } = props;

  const { activeStep, isFirstStep, isLastStep, goToPreviousStep, goToNextStep, goToFirstStep, goToLastStep } =
    useEvolutionPanelNavigator();

  return (
    <div className="evolutionpanelnavigator">
      <div
        className={`evolutionpanelnavigator-control evolutionpanelnavigator-index ${
          isFirstStep() ? 'disabled' : 'enabled'
        }`}
        onClick={goToFirstStep}
      >
        0
      </div>
      <div>
        <EllipsisIcon />
      </div>
      <div
        className={`evolutionpanelnavigator-control ${isFirstStep() ? 'disabled' : 'enabled'}`}
        onClick={goToPreviousStep}
      >
        <GoToPreviousIcon />
      </div>
      <div>{activeStep}</div>
      <div
        className={`evolutionpanelnavigator-control ${isLastStep() ? 'disabled' : 'enabled'}`}
        onClick={goToNextStep}
      >
        <GoToNextIcon />
      </div>
      <div>
        <EllipsisIcon />
      </div>
      <div
        className={`evolutionpanelnavigator-control evolutionpanelnavigator-index ${
          isLastStep() ? 'disabled' : 'enabled'
        }`}
        onClick={goToLastStep}
      >
        {lastStepIndex}
      </div>
    </div>
  );
}

export default React.memo(EvolutionPanelNavigator);
