import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTreatmentStepsManager } from './useTreatmentStepsManager';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { ModalTypes } from '../../../surgeries/components/layout';
import { ReactComponent as IconDelete } from '../../../assets/icons/delete.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import { ReactComponent as IconReset } from '../../../assets/icons/reset.svg';
import { useTranslation } from 'react-i18next';
import { ResetArch, Step } from '../../../common';
import { useTimelineStepsManager } from './useTimelineManager';
import { useToothTransformStore } from '../../../orthodontics/stores/useToothTransformStore';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../shared';

const getStepKeyIndexesFromSteps = (steps: Step[]) =>
  steps.filter((s) => s.isKeyStep === true).map((ks) => ks.stepIndex);

export function useIntermediateSteps(disableTreatmentEffects = false) {
  const ability = useContext(AbilityContext);
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const areMadeChangesOnTeeth = useOrthBoundStore((state) => state.areMadeChangesOnTeeth);
  const setCanAskAIInterpolation = useOrthBoundStore((state) => state.setCanAskAIInterpolation);
  const setAreMadeChangesOnTeeth = useOrthBoundStore((state) => state.setAreMadeChangesOnTeeth);
  const setIsRecalculateCancelled = useOrthBoundStore((state) => state.setIsRecalculateCancelled);

  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  const hasTeethBeenTransformed = useToothTransformStore((state) => state.hasTeethBeenTransformed);
  const setHasTeethBeenTransformed = useToothTransformStore((state) => state.setHasTeethBeenTransformed);

  const { stepsModifiedWithNoAutoRecalcIndexes, setStepToNavigate, updateMovements } =
    useTreatmentStepsManager(disableTreatmentEffects);

  const {
    upperSteps,
    lowerSteps,
    activeStep,
    lastStepIndex,
    selectedStepIndexes,
    setActiveStep,
    setSelectedStepIndexes
  } = useTimelineStepsManager();

  const keyStepIndexes = useMemo(() => {
    // WORKAROUND debido a que si hay cambios en una sóla arcada, en la otra siguen viniendo como keysteps los stepindex 0 y 1
    const upperStepsKeyIndexes = upperSteps?.length > 2 ? getStepKeyIndexesFromSteps(upperSteps) : [];
    const lowerStepsKeyIndexes = lowerSteps?.length > 2 ? getStepKeyIndexesFromSteps(lowerSteps) : [];

    if (upperStepsKeyIndexes.length >= 2 || lowerStepsKeyIndexes.length >= 2) {
      return Array.from(new Set([...upperStepsKeyIndexes, ...lowerStepsKeyIndexes]));
    }

    return [0, 1];
  }, [upperSteps, lowerSteps]);

  useEffect(() => {
    if (!selectedStepIndexes) {
      return;
    }

    setCanAskAIInterpolation(selectedStepIndexes.length === 2);
  }, [selectedStepIndexes]);

  const addStepAction = useCallback(async (): Promise<void> => {
    if (selectedStepIndexes.length === 0) {
      return;
    }

    const stepIndexToBeCloned = selectedStepIndexes[0];

    await window.App.webEventsProxy.movements.addNewStep(stepIndexToBeCloned);
    updateMovements();
    const newStepIndex = stepIndexToBeCloned + 1;
    setSelectedStepIndexes([newStepIndex]);
    setActiveStep(newStepIndex);
  }, [selectedStepIndexes, updateMovements, setSelectedStepIndexes, setActiveStep]);

  const removeStepsAction = useCallback(async (): Promise<void> => {
    if (selectedStepIndexes.length === 0) {
      return;
    }

    setWebBusy(true);
    await removeSteps(selectedStepIndexes);
    setWebBusy(false);
  }, [selectedStepIndexes, lastStepIndex]);

  const resetMovementsAction = () => {
    window.App.webEventsProxy.movements.resetArch(ResetArch.BOTH);
    setHasTeethBeenTransformed(false);
    setAreMadeChangesOnTeeth(false);
  };

  const removeSteps = useCallback(
    async (stepIndexes: number[]): Promise<void> => {
      if (!stepIndexes || stepIndexes.length === 0 || stepIndexes.includes(0)) {
        return;
      }
      await window.App.webEventsProxy.movements.removeStep(selectedStepIndexes[0]);
      updateMovements();

      const newStepIndex =
        lastStepIndex > 0 && lastStepIndex == selectedStepIndexes[0] ? lastStepIndex - 1 : selectedStepIndexes[0];

      setSelectedStepIndexes([newStepIndex]);
    },
    [updateMovements, setSelectedStepIndexes, selectedStepIndexes]
  );

  const handleModeRecalculateSteps = useCallback(
    (stepIndex: number) => {
      if (stepIndex < activeStep) {
        setSelectedStepIndexes([stepIndex, activeStep]);
      }
    },
    [activeStep]
  );

  const handlePreventedNavigation = useCallback(
    (stepIndex: number, isCtrlKeyPressed: boolean) => {
      if (areMadeChangesOnTeeth) {
        if (!isCtrlKeyPressed) {
          // toggleModalIsOpened(ModalTypes.ModalModificationsMade); // comentada esta línea y añadida la siguiente tras nuevo modal unificado; mantenido comentario por si se arrepintiera
          setIsRecalculateCancelled(true);
          setStepToNavigate(stepIndex);
          setSelectedStepIndexes([activeStep]);
        } else {
          handleModeRecalculateSteps(stepIndex);
        }
      }
    },
    [areMadeChangesOnTeeth, handleModeRecalculateSteps, activeStep]
  );

  const getNewStepIndexes = (oldStepIndexes: number[], stepIndex: number, isCtrlKeyPressed?: boolean) => {
    if (!isCtrlKeyPressed) {
      return [stepIndex];
    }

    if (oldStepIndexes.includes(stepIndex)) {
      return oldStepIndexes.filter((index) => index !== stepIndex);
    }

    if (oldStepIndexes.length === 0) {
      return [stepIndex];
    }

    return [oldStepIndexes[0], stepIndex];
  };

  const addStepToSelection = useCallback(
    (stepIndex: number, isCtrlKeyPressed?: boolean) => {
      const newStepIndexes = getNewStepIndexes(selectedStepIndexes, stepIndex, isCtrlKeyPressed);
      setSelectedStepIndexes(newStepIndexes);
    },
    [setSelectedStepIndexes, selectedStepIndexes]
  );

  const mustHideAddStepsAction = () => {
    return !ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.AddStepsManually);
  };

  const mustHideRemoveStepsAction = () => {
    const mustHideBecauseNoPermission =
      !ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.RemoveStepsManually);
    const mustHideBecauseNoAllowedSteps =
      selectedStepIndexes[0] === 0 || selectedStepIndexes.length > 1 || selectedStepIndexes[0] === lastStepIndex;

    return mustHideBecauseNoPermission || mustHideBecauseNoAllowedSteps;
  };

  const getIntermediateStepsContextMenuOptions = useCallback(() => {
    return [
      {
        labelKey: 'intermediateSteps.contextMenuOptions.addStep',
        action: addStepAction,
        icon: <IconPlus />,
        hide: mustHideAddStepsAction()
      },
      {
        labelKey: 'intermediateSteps.contextMenuOptions.removeStep',
        action: removeStepsAction,
        icon: <IconDelete />,
        hide: mustHideRemoveStepsAction()
      },
      {
        labelKey: 'intermediateSteps.contextMenuOptions.reset',
        icon: <IconReset />,
        action: resetMovementsAction,
        hide: !hasTeethBeenTransformed
      }
    ];
  }, [selectedStepIndexes, lastStepIndex, addStepAction, removeStepsAction, hasTeethBeenTransformed]);

  return {
    selectedStepIndexes,
    keyStepIndexes,
    stepsModifiedWithNoAutoRecalcIndexes,
    addStepAction,
    removeStepsAction,
    handleModeRecalculateSteps,
    handlePreventedNavigation,
    addStepToSelection,
    getIntermediateStepsContextMenuOptions
  };
}
