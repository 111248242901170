/////////
// Enums
/////////

import { TeethArchPosition } from '../../models';

export enum CapturePosition {
  UPPER = 0,
  LOWER = 1,
  BOTH = 2,
  BOTH_OPEN = 3,
  NONE = 4
}

export enum ResetArch {
  UPPER = 0,
  LOWER = 1,
  BOTH = 2
}

export enum CaptureType {
  DICOM = 0,
  MODEL3D = 1,
  PHOTO = 2
}

export enum Split {
  Single = 1,
  Split2 = 2,
  Split2H = 3,
  Split4 = 4
}

export enum Stage {
  Empty = 0,
  LoadSTL = 1,
  STLEdition = 2,
  TeethSegmentation = 3,
  AxisAndRoots = 4,
  Treatment = 5,
  Publish = 6,
  Client = 7,

  MatchingSetup = 30,
  MatchingResult = 31,
  Implants = 32
}

export enum SceneType {
  GuidedSurgery = 0,
  Orthodontics = 1
}

export enum Color {
  Yellow = 0,
  Green = 1,
  Blue = 2,
  Red = 3
}

export enum Model3dType {
  Scan = 0,
  Implant = 1,
  Tooth = 2,
  Gum = 3,
  Label = 4,
  Attachment = 5,
  Roots = 6
}

export enum CameraPosition {
  Front = 0,
  Back = 1,
  Left = 2,
  Right = 3,
  Top = 4,
  Bottom = 5,
  VestibularHighlightRight = 6,
  IncisorHighlight = 7,
  VestibularHighlightLeft = 8
}

export enum PagesWithTools {
  MeshCorrection = 1,
  ArchPosition = 2,
  TeethDetector = 3,
  Roots = 4,
  VectorsDirection = 5,
  Superposition = 6,
  Lines = 7,
  TeethMovement = 8,
  Adjunts = 9,
  Pontics = 10,
  Tags = 11,
  StlEdition = 12,
  Segmentation = 13,
  Treatment = 14,
  DentalMovements = 15
}

export enum Axis {
  X = 0,
  Y = 1,
  Z = 2
}

export enum AttachmentState {
  Start = 'start',
  End = 'end',
  Both = 'both',
  None = 'none'
}

export enum ApplyIprState {
  PreIpr = 'preIpr',
  ApplyIpr = 'applyIpr',
  PostIpr = 'postIpr',
  None = 'none'
}

export enum MovementType {
  ExtrusionIntrusion = 'extrusionIntrusion',
  TranslationVL = 'translationVL',
  TranslationMD = 'translationMD',
  PureRotation = 'pureRotation',
  MesialRotation = 'mesialRotation',
  DistalRotation = 'distalRotation',
  Torque = 'torque',
  TIP = 'tip',
  MultiplePlane = 'multiplePlane',
  Reset = 'reset'
}

export enum SourceOfMovement {
  Crown = 'crown',
  Root = 'root'
}

/////////
// Types
/////////

export type Model = {
  id: string;
  uri: string;
  teethArch: CapturePosition;
  model3dType?: Model3dType;
  info?: ImplantInfo;
};

export type ImplantInfo = {
  company: string;
  model: string;
  diameter: number;
  length: number;
};

export type ModelInstance = {
  modelId: string;
  transform?: Matrix4x4;
};

export type Viewport = {
  modelInstances: ModelInstance[];
};

export type EvergineError = {
  type: string;
  title: string;
  detail: string;
  instance: string;
  fromEvent: string;
};

export type Vector2 = {
  x: number;
  y: number;
};

export type Vector3 = {
  x: number;
  y: number;
  z: number;
};

export type Matrix4x4 = number[];

export type MatchingPoint = {
  position: Vector3 | null;
  color: number;
};

export type MatchingModelPoint = {
  point: MatchingPoint;
  modelId: string;
};

export type ActionCommand = {
  id: string;
};

export type MatchingInfo = {
  dicom: string;
  model3D: string;
  dicomPoints: MatchingPoint[];
  model3dPoints: MatchingPoint[];
};

export type Tooth = {
  id: string;
  fdi: number;
  mesioDistalLength: number;
  rotationCenter: Vector3;
  mesioDistalVector: Vector3;
  upVector: Vector3;
  palatalVector: Vector3;
  edgeLoopID: number;
  extractedInStep?: number;
};

export type MesioDistalLength = {
  [fdi: number]: number;
};

export type InterdentalDistance = {
  leftToothId: string;
  rightToothId: string;
  distance: number;
  leftFdi: number;
  rightFdi: number;
};

export type ToothMovement = {
  toothId: string;
  transform?: Matrix4x4;
  movedInCurrentStep: boolean;
};

export type LabelMovement = {
  labelId: string;
  negative: boolean;
  transform: Matrix4x4;
};

export type Gum = {
  id: string;
  stepIndex: number;
};

export enum AttachmentType {
  Unknown,
  RotationRight,
  RotationLeft,
  ExtrusionAnteriorConventional,
  ExtrusionAnteriorOptimized,
  ExtrusionPosteriorOptimized,
  RectangularHorizontalOptimized,
  RectangularVerticalOptimized,
  RectangularRightBeveled,
  RectangularLeftBeveled,
  RadicularControlDoubleOne,
  RadicularControlDoubleTwo,
  RadicularControlSimple,
  NegativePressureAreas,
  NegativePressurePoints,
  ControlExpansionOptimized,
  ControlRetentionOptimized,
  ControlSupportOptimized,
  BiteLiftAnterior,
  BiteLiftPosterior,
  // TADS
  CutDistal,
  CutMesial,
  Button,
  // Labels
  StepNumber
}

export enum AttachmentStepMode {
  None,
  AllSteps,
  FromCurrentStepToEnd,
  FromCurrentStepToStart
}

export type Attachment = {
  id: string;
  toothId: string;
  type: AttachmentType;
  transform: Matrix4x4;
  firstStep: number;
  lastStep: number;
};

export type AttachmentDto = {
  id: string;
  toothFdi: number;
  type: AttachmentType;
};

export type AttachmentPositionInfo = {
  attachmentDto: AttachmentDto;
  screenPosition: Vector2;
  thickness: AttachmentThicknessDto;
  firstStep: number;
  lastStep: number;
};

export type AttachmentThicknessDto = {
  currentThickness: number;
  canIncrement: boolean;
  canDecrement: boolean;
};

export type TooltipAttach = {
  id: string;
  toothFdi: number;
  position: {
    x: number;
    y: number;
  };
  type: number;
  thickness: {
    currentThickness: number;
    canIncrement: boolean;
    canDecrement: boolean;
  };
  firstStep: number;
  lastStep: number;
};

export type Label = {
  labelId: string;
  toothId: string;
};

export enum VirtualTemplateType {
  PositiveAttache,
  PassiveAligner
}

export type Step = {
  stepIndex: number;
  toothMovements: ToothMovement[];
  ipr: InterdentalDistance[];
  realTimeInterdentalDistances?: InterdentalDistance[];
  jawTransform: Matrix4x4;
  isClientModification?: boolean;
  isKeyStep?: boolean;
  isModifiedStepWithNoAutoRecalc?: boolean;
  virtualTemplateType?: VirtualTemplateType;
  virtualTemplateIndex?: string;
};

export type IprLabel = {
  fdiLeft: number;
  fdiRight: number;
  value: number;
  applyStepIndex: number;
  maxStepIndex: number;
  strippingRatio: number;
};

export type DentalMovementPair = {
  upperDentalMovements: DentalMovementGroup | null;
  lowerDentalMovements: DentalMovementGroup | null;
};

export type DentalMovementGroup = {
  teethArch: CapturePosition;
  gum: Gum;
  teeth: Tooth[];
  attachments: Attachment[];
  labels: Label[];
  steps: Step[];
  iprLabels: IprLabel[];
  finalPositionHasBeenReplacedByClient: boolean;
};

export type DentalMovements = {
  upper: DentalMovementGroup;
  lower: DentalMovementGroup;
};

export type Segmentation = {
  upper: number[];
  lower: number[];
};

export type VirtualElementPosition = {
  position: Vector2;
  size: number;
};

export type IprLabelPosition = {
  leftToothFdi: number;
  rightToothFdi: number;
  leftToothId: string;
  rightToothId: string;
  position: Vector2;
  size: number;
  visible: boolean;
  teethArch: CapturePosition;
  isComparer: boolean;
  stepIndex: number;
  distance: number;
  iprDistance: number;
};

export type ToothChange = {
  toothFdi: number;
  kind: MovementType;
  source?: SourceOfMovement;
  value?: number;
};

export enum ToothState {
  Normal = 0,
  Lock = 1,
  Inmovable = 2
}

export type ToothHoverData = {
  fdi: number;
  toothState: ToothState;
};

export type SegmentationNumerationChangeEvalResult = {
  canBeAutomaticallyResolved: boolean;
  conflictReasonNumber?: number;
};

export enum SegmentationNumerationStrategy {
  SwapNumbers = 0,
  RenumberToDistal = 1,
  RenumberToMesial = 2
}

export enum SegmentationFailReason {
  None = 0,
  Unknown = 1,
  NotEnoughTeeth = 2
}

export type SegmentationProcessingResult = {
  upperSegmentationData: number[];
  lowerSegmentationData: number[];
  upperMovements: DentalMovementGroup;
  lowerMovements: DentalMovementGroup;
  segmentationFailReason: SegmentationFailReason;
};

export type ToothRotationAxisPrediction = {
  tooth: number;
  rotation_center_x: number;
  rotation_center_y: number;
  rotation_center_z: number;
  upper_pos_x: number;
  upper_pos_y: number;
  upper_pos_z: number;
  mesio_pos_x: number;
  mesio_pos_y: number;
  mesio_pos_z: number;
};

export type ToothRotationAxisChange = {
  toothId: string;
  toothFdi: number;
  rotationCenter: Vector3;
  mesioDistalVector: Vector3;
  upVector: Vector3;
  palatalVector: Vector3;
};

export type BoltonToothDto = {
  fdi: number;
  isMissing: boolean;
  mesioDistalLength: number;
};

export type BoltonDataDto = {
  upperTeeth: BoltonToothDto[];
  lowerTeeth: BoltonToothDto[];
  bolton3Ratio: number;
  bolton6Ratio: number;
  bolton3Percent: number;
  bolton6Percent: number;
};

export type InterdentalLabelData = {
  leftToothId: string;
  rightToothId: string;
  distance: number;
  applyIpr?: boolean;
  postApplyIpr?: boolean;
  leftIpr: boolean;
  rightIpr: boolean;
  leftFdi: number;
  rightFdi: number;
};

export type StepInterdentalDistance = {
  stepIndex: number;
  distances: InterdentalDistance[];
};

export type IntermediateStepsProcessingResult = {
  succeeded: boolean;
  upperDistances: StepInterdentalDistance[];
  lowerDistances: StepInterdentalDistance[];
  upperIprs: IprLabel[];
  lowerIprs: IprLabel[];
};

export type InputIntermediateSteps = {
  blocked_teeth: string[];
  total_mov_teeth: {
    toothId: string;
    position: number[];
    rotation: number[];
  }[];
};

export type OutputIntermediateSteps = {
  steps: {
    stepIndex: number;
    teethTransformationData: {
      toothFdi: number;
      transformations: number[];
    }[];
  }[];
};

export type ScanOrientation = {
  scanType: TeethArchPosition;
  transformationValues: number[];
};

/////////
// Alias
/////////

export type ToothTransformInfo = {
  toothFdi: number;
  transform: number[];
  extrusionIntrusion: number;
  translationVL: number;
  translationMD: number;
  pureRotation: number;
  torque: number;
  tip: number;
};

export type InputAttachments = {
  steps: {
    stepIndex: number;
    teethTransformationData: {
      toothFdi: number;
      toothHeight: number;
      toothMesioDistalLength: number;
      transformations: number[];
    }[];
  }[];
};

export type OutputAttachments = {
  attaches: {
    toothFdi: number;
    firstStep: number;
    lastStep: number;
    attachmentType: AttachmentType;
  }[];
};

export enum MovementCalculationType {
  Total,
  Accumulated,
  Step
}
