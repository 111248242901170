import { MeasureUnit } from '../../../../../models';
import { StlEditionTarget } from '../stlEdition.types';

export enum StlOrientationMovementTypes {
  RotationAxisX = 'rotationAxisX',
  RotationAxisY = 'rotationAxisY',
  RotationAxisZ = 'rotationAxisZ',
  LeftRight = 'leftRight',
  UpBottom = 'upBottom',
  InsideOutside = 'insideOutside'
}

export type OrientationValue = {
  value: number;
  measureUnit: MeasureUnit;
};

export type MovementTypeValue = {
  [key in StlOrientationMovementTypes]: OrientationValue;
};

export type StlOrientationValue = {
  [key in StlEditionTarget]: MovementTypeValue;
};

export type StlOrientationToolsType = {
  type: StlOrientationMovementTypes;
  titleKey: string;
  measureUnitType: MeasureUnit;
};

export const EDITED_UPPER_MATRIX_FILE_KEY = 'upper-matrix-edited';
export const EDITED_LOWER_MATRIX_FILE_KEY = 'lower-matrix-edited';
