import { useTranslation } from 'react-i18next';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import { useCallback, useEffect, useMemo } from 'react';
import { ToolPanelDraggableContainer } from '../../ToolPanelDraggableContainer';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as Reset } from '../../../../../../assets/icons/reset.svg';
import { ReactComponent as Plus } from '../../../../../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../../../../../assets/icons/minus.svg';
import { MeasureUnit } from '../../../../../models';
import { DEGREES_STEP_NUMBER, MM_STEP_NUMBER } from '../../teethMovements/OrthTeethTools.helper';
import { OrthToolButtonSmall } from '../../teethMovements/orthToolButtonSmall';
import { StlOrientationMovementTypes, StlOrientationToolsType } from './stlOrientation.types';
import { useStlOrientation } from '../../../../../../hooks';
import { OrthToolButtonReset } from '../../teethMovements/orthToolButtonReset';
import { TOOLS_STL_ORIENTATION_BUTTON_ID } from '../../../stlEditionToolbarElements';
import { OperationType, StlEditionTarget } from '../stlEdition.types';
import { getIconForOrientation } from '../stlEdition.helper';
import { useCommonBoundStore } from '../../../../../../common/stores/useStore';
import { ArchsToTreat } from '../../../../../../shared';

const PANEL_SIZE = 312;
const HANDLER_DRAG_ELEMENT_CLASS = 'handleStlOrientation';

const stlOrientationTools: StlOrientationToolsType[] = [
  {
    type: StlOrientationMovementTypes.RotationAxisX,
    titleKey: 'stlEdition.orientationTool.rotationAxisX',
    measureUnitType: MeasureUnit.Degrees
  },
  {
    type: StlOrientationMovementTypes.RotationAxisY,
    titleKey: 'stlEdition.orientationTool.rotationAxisY',
    measureUnitType: MeasureUnit.Degrees
  },
  {
    type: StlOrientationMovementTypes.RotationAxisZ,
    titleKey: 'stlEdition.orientationTool.rotationAxisZ',
    measureUnitType: MeasureUnit.Degrees
  },
  {
    type: StlOrientationMovementTypes.LeftRight,
    titleKey: 'stlEdition.orientationTool.leftRight',
    measureUnitType: MeasureUnit.Millimeter
  },
  {
    type: StlOrientationMovementTypes.UpBottom,
    titleKey: 'stlEdition.orientationTool.upBottom',
    measureUnitType: MeasureUnit.Millimeter
  },
  {
    type: StlOrientationMovementTypes.InsideOutside,
    titleKey: 'stlEdition.orientationTool.insideOutside',
    measureUnitType: MeasureUnit.Millimeter
  }
];

export function StlOrientationTools() {
  const [t] = useTranslation();
  const setShowStlOrientationPanel = useOrthBoundStore((state) => state.setShowStlOrientationPanel);
  const archsToBeTreated = useCommonBoundStore((state) => state.archsToBeTreated);

  const {
    selectedOrientationTarget,
    onSelectOrientationTarget,
    getInputValue,
    onChangeInput,
    onInputKeyPress,
    onAddOrRemoveValueToInput,
    getMaxValue,
    onClickResetAll
  } = useStlOrientation();

  const toolPosition = useMemo(() => {
    return document.getElementById(TOOLS_STL_ORIENTATION_BUTTON_ID)?.getBoundingClientRect();
  }, []);

  const getMovementTab = useCallback(
    (orientationTarget: StlEditionTarget) => {
      if (
        (orientationTarget === StlEditionTarget.Upper && archsToBeTreated === ArchsToTreat.Lower) ||
        (orientationTarget === StlEditionTarget.Lower && archsToBeTreated === ArchsToTreat.Upper)
      ) {
        return null;
      }

      return (
        <button
          className={`orth-movements__button ${orientationTarget === selectedOrientationTarget ? 'is-active' : ''}`}
          onClick={() => {
            onSelectOrientationTarget(orientationTarget);
          }}
        >
          {getIconForOrientation(orientationTarget)}
        </button>
      );
    },
    [onSelectOrientationTarget, selectedOrientationTarget]
  );

  const MovementToolComponent = useCallback(
    (tool: StlOrientationToolsType, index: number) => {
      const [t] = useTranslation();

      return (
        <div className={`orth-movements__content`} key={`s-${index}`}>
          <div className="orth-movements__row">
            <div className="orth-movements__label">{t(tool.titleKey)}</div>
            <>
              <input
                className="orth-movements__input"
                value={getInputValue(tool.type)}
                onChange={(e) => onChangeInput(e.target.value, tool.type)}
                onKeyPress={(e: any) => onInputKeyPress(e, tool.type)}
                placeholder={`0.0 ${tool.measureUnitType}`}
                max={getMaxValue(tool.measureUnitType)}
                min={-getMaxValue(tool.measureUnitType)}
                step={tool.measureUnitType === MeasureUnit.Millimeter ? MM_STEP_NUMBER : DEGREES_STEP_NUMBER}
              />

              <div className="orth-movements__row">
                <OrthToolButtonSmall Icon={Plus} onClick={() => onAddOrRemoveValueToInput(tool, OperationType.ADD)} />
                <OrthToolButtonSmall
                  Icon={Minus}
                  onClick={() => onAddOrRemoveValueToInput(tool, OperationType.SUBSTRACT)}
                />
              </div>
            </>
          </div>
        </div>
      );
    },
    [getInputValue]
  );

  return (
    <>
      {!!toolPosition && (
        <ToolPanelDraggableContainer
          panelSize={PANEL_SIZE}
          toolPosition={toolPosition}
          handlerElementClass={HANDLER_DRAG_ELEMENT_CLASS}
        >
          <div className="orth-movements">
            <div className="orth-movements__header">
              <div className={`orth-movements__title ${HANDLER_DRAG_ELEMENT_CLASS}`}>
                <div>{t('evergineTools.stlPosition')}</div>
              </div>

              <button type="button" className="orth-movements__close" onClick={() => setShowStlOrientationPanel(false)}>
                <CloseIcon className="clickable" />
              </button>
            </div>
            <div className="orth-movements__separator" />
            <div className="orth-movements__reset">
              <OrthToolButtonReset Icon={Reset} isDisabled={false} onClick={onClickResetAll} />
            </div>

            <div className="orth-movements__separator" />
            {archsToBeTreated === ArchsToTreat.Both && (
              <div className="orth-movements__buttons-container">
                {getMovementTab(StlEditionTarget.Both)}
                {getMovementTab(StlEditionTarget.Upper)}
                {getMovementTab(StlEditionTarget.Lower)}
              </div>
            )}
            <div className="orth-movements__teeth-tool">
              <div className="orth-movements__content">
                <div className="scroll">
                  {stlOrientationTools.map((tool: StlOrientationToolsType, index: number) =>
                    MovementToolComponent(tool, index)
                  )}
                </div>
              </div>
            </div>
          </div>
        </ToolPanelDraggableContainer>
      )}
    </>
  );
}
