import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useOrthBoundStore } from '../../../../stores/useStore';

import { AttacheType } from './attacheType/AttacheType';

import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useAttaches, useAttachesCalculation } from '../../../../../hooks';
import { ModalTypes } from '../../../../../surgeries/components/layout';
import { attacheTypes } from './attacheTypes';
import './attachesTools.scss';
import { ToolPanelDraggableContainer } from '../ToolPanelDraggableContainer';

const PANEL_SIZE = 312;

export function AttachesTools() {
  const setShowAttachesPanel = useOrthBoundStore((state) => state.setShowAttachesPanel);
  const setAttachActive = useOrthBoundStore((state) => state.setAttachActive);
  const selectedAttache = useOrthBoundStore((state) => state.selectedAttache);
  const setShowTadsPanel = useOrthBoundStore((state) => state.setShowTadsPanel);
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);

  const [t] = useTranslation();
  const { inFirstOrLastStep, noAttacheSelected, isAttachedSelectedBelongToSelectedTooth } = useAttaches();
  const { calculateAttaches } = useAttachesCalculation();

  const selectedAttacheRef = useRef(selectedAttache);
  const toolPosition = useMemo(() => {
    return document.getElementById('orthAttachesButton')?.getBoundingClientRect();
  }, []);

  const handleClose = () => {
    setShowAttachesPanel(false);
    setAttachActive(null);
  };

  const showDeleteModal = () => {
    toggleModalIsOpened(ModalTypes.ModalDeleteAttach);
  };

  const invokeAttachmentsAICalc = async () => {
    await calculateAttaches();
  };

  const mustDisableRemove = () => {
    return noAttacheSelected || inFirstOrLastStep || !isAttachedSelectedBelongToSelectedTooth;
  };

  useEffect(() => {
    selectedAttacheRef.current = selectedAttache;
  }, [selectedAttache]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete' && !!selectedAttacheRef.current) {
        showDeleteModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    setShowTadsPanel(false);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <ToolPanelDraggableContainer
      handlerElementClass="handleOrthAttaches"
      panelSize={PANEL_SIZE}
      toolPosition={toolPosition}
    >
      <div className="attachestools no-select">
        <div className="attachestools-header">
          <div className="attachestools-title handleOrthAttaches">
            <div>{t('evergineTools.attaches')}</div>
          </div>

          <button type="button" className="attachestools-close" onClick={handleClose}>
            <CloseIcon className="clickable" />
          </button>
        </div>
        <div className="attachestools-content">
          {attacheTypes.map((attacheType) => (
            <AttacheType attacheType={attacheType} key={`attachetype-${attacheType.name}`} />
          ))}
          <button
            onClick={showDeleteModal}
            type="button"
            className="btn btn-outline-primary flex-fill attachestools-content__delete"
            disabled={mustDisableRemove()}
          >
            {t('common.delete')}
          </button>
          <button
            onClick={invokeAttachmentsAICalc}
            type="button"
            className="btn btn-outline-primary flex-fill attachestools-content__delete"
          >
            {t('evergineTools.autoAttachesCalc')}
          </button>
        </div>
      </div>
    </ToolPanelDraggableContainer>
  );
}
