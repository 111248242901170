import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as MinusIcon } from '../../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

import './modifyAttachesThickness.scss';
import { useEvergineStore } from 'evergine-react';
import { useOrthBoundStore } from '../../../stores/useStore';
import { TooltipAttach } from '../../../../common';

enum OperationType {
  ADD,
  SUBSTRACT
}

const fixFloat = (value: number) => parseFloat(value.toFixed(1));

interface ModifyAttachesThicknessProps {
  onClickOutside: () => void;
}

export function ModifyAttachesThickness({ onClickOutside }: ModifyAttachesThicknessProps) {
  const { evergineReady } = useEvergineStore();
  const { selectedAttache } = useOrthBoundStore((state) => ({
    selectedAttache: state.selectedAttache
  }));

  const [modifyingAttach, setModifyingAttach] = useState<TooltipAttach | null>(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClickOutside();
      }
    },
    [onClickOutside, dropdownRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (selectedAttache !== null) {
      setModifyingAttach(selectedAttache);
    }
  }, [selectedAttache]);

  const modifyAttachThickness = useCallback(
    (operationType: OperationType) => {
      if (!evergineReady || !modifyingAttach) {
        return;
      }

      let newValue = modifyingAttach?.thickness.currentThickness;
      newValue += operationType === OperationType.ADD ? 0.1 : -0.1;
      newValue = fixFloat(newValue);

      const newAttacheThicknessData = window.App.webEventsProxy.attachments.updateAttachmentThickness(
        modifyingAttach.id,
        newValue
      );

      const newAttachData = Object.assign({}, modifyingAttach);
      newAttachData.thickness = newAttacheThicknessData;
      setModifyingAttach(newAttachData);
    },
    [evergineReady, modifyingAttach]
  );

  return (
    <div className="attachesthickness" ref={dropdownRef}>
      <div
        className={`attachesthickness-operator ${modifyingAttach?.thickness.canDecrement ? 'clickable' : 'disabled'}`}
        onClick={() => modifyAttachThickness(OperationType.SUBSTRACT)}
      >
        <MinusIcon />
      </div>
      <div>
        {modifyingAttach?.thickness.currentThickness !== undefined
          ? fixFloat(modifyingAttach?.thickness.currentThickness)
          : '--'}
        mm
      </div>
      <div
        className={`attachesthickness-operator ${modifyingAttach?.thickness.canIncrement ? 'clickable' : 'disabled'}`}
        onClick={() => modifyAttachThickness(OperationType.ADD)}
      >
        <PlusIcon />
      </div>
    </div>
  );
}
