import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { AttachmentType, IconTextInfo } from '../../../../../../common';
import { useUtils } from '../../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import { useAttaches, useDentalMovementsSnapshot } from '../../../../../../hooks';
import { useCommonBoundStore } from '../../../../../../common/stores/useStore';
import { ModalTypes } from '../../../../../../surgeries/components/layout';

import './attacheModel.scss';
import { isTadType } from '../../tads';

interface AttacheModelProps {
  model: IconTextInfo;
}

export function AttacheModel({ model }: AttacheModelProps) {
  const [t] = useTranslation();
  const { updateMovements } = useUtils();
  const { noTeethSelected, inFirstOrLastStep, noAttacheSelected } = useAttaches();
  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();
  const selectedTeethId = useOrthBoundStore((state) => state.selectedTeethId);
  const attachActive = useOrthBoundStore((state) => state.attachActive);
  const setAttachActive = useOrthBoundStore((state) => state.setAttachActive);
  const selectedAttache = useOrthBoundStore((state) => state.selectedAttache);
  const setAttachementTypeToBeAdded = useOrthBoundStore((state) => state.setAttachementTypeToBeAdded);
  const addingAttache = useOrthBoundStore((state) => state.addingAttache);
  const setAddingAttache = useOrthBoundStore((state) => state.setAddingAttache);
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);

  useEffect(() => {
    setAddingAttache(null);
  }, [selectedTeethId, selectedAttache]);

  const modalForAttachmentType = (type: AttachmentType) => {
    if (isTadType(type)) {
      return ModalTypes.ModalAddTAD;
    }

    return ModalTypes.ModalAddAttach;
  };

  const updateMovementsAndSnapshot = () => {
    updateMovements();
    updateDentalMovementsSnapshot();
  };

  const handleAttach = (type: AttachmentType) => {
    setAttachementTypeToBeAdded(type);
    setAttachActive(type);

    if (selectedTeethId.length > 0 && !selectedAttache && !addingAttache) {
      toggleModalIsOpened(modalForAttachmentType(type));
    } else if (selectedAttache && !!selectedAttache.id) {
      window.App.webEventsProxy.attachments.changeAttachmentType(selectedAttache.id, type);
      updateMovementsAndSnapshot();
    } else if (addingAttache) {
      window.App.webEventsProxy.attachments.changeAttachmentType(addingAttache.id, type);
      updateMovementsAndSnapshot();
    }
  };

  const isActive = useMemo(() => {
    if (attachActive === model.typeAtt) {
      return true;
    }
    return false;
  }, [attachActive]);

  const mustDisable = useCallback(() => {
    return (noTeethSelected && noAttacheSelected) || inFirstOrLastStep;
  }, [noTeethSelected, noAttacheSelected, inFirstOrLastStep]);

  return (
    <div className="attachemodel" key={model.id}>
      <button
        data-for={model.id}
        data-tip
        id={model.id}
        disabled={mustDisable()}
        className={`etoolbar-button clickable ${isActive ? 'is-active' : ''}`}
        onClick={() => handleAttach(model.typeAtt)}
      >
        <div>{<model.icon />}</div>
        <ReactTooltip id={model.id} place="top" effect="solid" className="tooltip" type="dark">
          {t(model.text)}
        </ReactTooltip>
      </button>
    </div>
  );
}
