import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { DentalMovementDTO, MovementsTableDto } from '../../shared';
import { useUtils } from './useUtils';

const MOVEMENT_LIMITS = {
  extrusionIntrusion: 0.12,
  translation: 0.25,
  pureRotation: 3,
  torque: 2,
  tip: 2
};

export function useMovementTable() {
  const movementsTable = useOrthBoundStore((state) => state.movementsTable);
  const setMovementsTable = useOrthBoundStore((state) => state.setMovementsTable);

  const { fixFloat } = useUtils();

  const getMovementsTable = async (): Promise<MovementsTableDto> => {
    const response = await window.App.webEventsProxy.movements.getMovementsTable();
    setMovementsTable(response);

    return response;
  };

  const getRelativeDentalMovementsByStep = (stepIndex: number, movements: MovementsTableDto): DentalMovementDTO[] => {
    return movements?.movementsRelative[stepIndex] || [];
  };

  const isOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    if (!relativeDentalMovement) return false;

    if (
      isExtrussionOverMovement(relativeDentalMovement) ||
      isTranslationOverMovement(relativeDentalMovement) ||
      isRotationOverMovement(relativeDentalMovement) ||
      isTorqueOverMovement(relativeDentalMovement) ||
      isTIPOverMovement(relativeDentalMovement)
    ) {
      return true;
    }

    return false;
  };

  const isExtrussionOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    const extrusionIntrusion = fixFloat(relativeDentalMovement.extrusionIntrusion, 2);
    return (
      extrusionIntrusion > MOVEMENT_LIMITS.extrusionIntrusion ||
      extrusionIntrusion < -MOVEMENT_LIMITS.extrusionIntrusion
    );
  };

  const isTranslationOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    const translationVL = fixFloat(relativeDentalMovement.translationVL, 2);
    const translationMD = fixFloat(relativeDentalMovement.translationMD, 2);
    return (
      translationVL > MOVEMENT_LIMITS.translation ||
      translationVL < -MOVEMENT_LIMITS.translation ||
      translationMD > MOVEMENT_LIMITS.translation ||
      translationMD < -MOVEMENT_LIMITS.translation
    );
  };

  const isRotationOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    const pureRotation = fixFloat(relativeDentalMovement.pureRotation, 2);
    return pureRotation > MOVEMENT_LIMITS.pureRotation || pureRotation < -MOVEMENT_LIMITS.pureRotation;
  };

  const isTorqueOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    const torque = fixFloat(relativeDentalMovement.torque, 2);
    return torque > MOVEMENT_LIMITS.torque || torque < -MOVEMENT_LIMITS.torque;
  };

  const isTIPOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    const tip = fixFloat(relativeDentalMovement.tip, 2);
    return tip > MOVEMENT_LIMITS.tip || tip < -MOVEMENT_LIMITS.tip;
  };

  return {
    movementsTable,
    getMovementsTable,
    getRelativeDentalMovementsByStep,
    isOverMovement
  };
}
